import React from 'react';
import { graphql, PageProps } from 'gatsby';
import LayoutMain from '../components/layouts/LayoutMain';
import SectionFaq from '../components/sectionFaq/SectionFaq';
import SEO from '../components/seo/SEO';

import "../styles/pageSupport.scss"
import SubpageHeaderBar from "../components/subpageHeaderBar/SubpageHeaderBar";

const SupportPage = (props: PageProps) => {
  const { data } = props;

  return (
    <LayoutMain>
      <SEO title="Welltot Help Center" description="Welltot Help Center"  bodyClassname="supportpage" />

      <SubpageHeaderBar
          header1="Help Center"
          description="Our patient assistants are delighted to help you with your orders, advice, and more. Please search your question below or use the form to get in touch."
          />

      <div className="container-faqs">
         <SectionFaq data={data.allPrismicWelltotIfuInstruction} />
      </div>
    </LayoutMain>
  );
};

export const query = graphql`
query SupportPage {
  allPrismicWelltotIfuInstruction(sort: {fields: data___weight, order: ASC}) {
    edges {
      node {
        uid
        data {
          title {
            text
          }
          body {
            html
          }
          lbl
        }
      }
    }
  }
}

`;
export default SupportPage;
