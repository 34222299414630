import React from "react";
import {getImage, IGatsbyImageData} from "gatsby-plugin-image";
import BackgroundImage from "gatsby-background-image";
import {graphql, PageProps, useStaticQuery} from "gatsby";
import {convertToBgImage} from "gbimage-bridge";
import * as styles from './subpageHeaderBar.module.scss';

type Props = {
    header1: string;
    header2: string;
    description: string;
};

const LayoutTotLife = ({ header1, header2, description }: Props) => {
    const data = useStaticQuery(query)
    const backgroundImage = convertToBgImage(getImage(data.placeholderImage ));

    return (
        <BackgroundImage
            {...backgroundImage}
            preserveStackingContext
            className={styles.subpageHeaderBar}
        >
            <h1>{header1}</h1>
            <h2>{header2}</h2>
            <h3>{description}</h3>
        </BackgroundImage>
    );
};

const query = graphql`
    query {
      placeholderImage: file(
        sourceInstanceName: {eq: "images"}
        relativePath: {eq: "page-images/mom-and-child-tinted.jpg"}
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP, AVIF])
        }
      }
    }
`;


export default LayoutTotLife;